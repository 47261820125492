<template>
  <section class="content-ranking">
    <div class="content-ranking-itens">
      <div class="content-ranking-itens-banner"
        :style="{ backgroundImage: `url(${require('@/assets/img/bg-ranking.png')})` }">
        <div class="content-ranking-itens-banner--title">
          <h1>Ranking</h1>
        </div>
      </div>
      <div v-if="isLoaded" class="container">
        <div class="content-box" v-if="campaign.balances.length > 0">
          <div class="content-box-position" v-for="(balance, key) in campaign.balances" :key="key"
            :class="{ 'my--position': user.id === balance.user.id }">

            <div class="content-box-position-details">
              <p class="number">{{ key + 1 }}</p>
              <p class="name">{{ balance.user.name }} {{ balance.user.nickname || '' }}</p>
            </div>
            <div class="content-box-position-value">
              <p>{{ balance.balance | money }}</p>
            </div>
          </div>
          <div class="content-box-position my--position"
            v-if="campaign.user_rank && campaign.user_rank.rank > 10 && Object.keys(user).length">
            <div class="content-box-position-details">
              <p class="number">{{ campaign.user_rank.rank }}º</p>
              <p class="name">{{ user.name }}</p>
            </div>
            <div class="content-box-position-value">
              <p>{{ campaign.user_rank.balance | money }}</p>
            </div>
          </div>
        </div>
        <div class="content-box" v-else>
          <div class="content-box-position justify-content-center">
              <h2>Nenhuma Lead Aprovada</h2>
          </div>
        </div>
        
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';

export default {
  data() {
    return {
      campaign: {},
      isLoaded: false,
    }
  },
  computed: {
    ...mapGetters('User', ['user']),

  },
  methods: {
    ...mapActions('App', ['loading']),
    ...mapActions('User', ['getUser']),
    async getDetails() {
      this.loading(true);
      await axios({
        method: 'GET',
        url: '/api/v1/campaign/detail',
        params: {
          slug: this.$route.params.slug,
          user_id: this.user.id
        }
      }).then(response => response.data.data)
        .then((data) => {
          if (!data || Object.keys(data).length == 0) {
            this.$router.push({ name: 'ranking' });
          }

          this.campaign = data;
          this.loading(false);
          this.isLoaded = true;
        })
    },

  },
  async mounted() {
    this.$nextTick(async () => {
      await this.getDetails();
      if (Object.keys(this.user).length == 0) {
        await this.getUser();
      }
    })

  }
}
</script>

<style lang="scss">
@import './index.scss';
</style>
